/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// Node.js library to set properties for components
import PropTypes from "prop-types";
// Material-UI components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
// Styles
import buttonStyle from "../../assets/jss/material-dashboard-react/components/buttonStyle.jsx";
// Classnames utility
import classNames from "classnames";

function RegularButton(props) {
  const {
    classes,
    color: initialColor = "primary", // Default value for color
    size,
    round = false,
    simple = false,
    disabled = false,
    block = false,
    link = false,
    justIcon = false,
    className,
    muiClasses,
    children,
    ...rest
  } = props;

  const [color, setColor] = useState(initialColor);

  useEffect(() => {
    setColor(initialColor === "" ? "primary" : initialColor);
  }, [initialColor]);

  // Dynamically set classes
  const btnClasses = classNames(
    classes.button,
    {
      [classes[size]]: size,
      [classes[color]]: classes[color], // Only apply if the color exists in styles
      [classes.round]: round,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon
    },
    className // Additional class passed via props
  );

  return (
    <Button {...rest} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
}

// PropTypes validation
RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  round: PropTypes.bool,
  simple: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node.isRequired,
};

// Default Props
RegularButton.defaultProps = {
  color: "primary",
  size: undefined,
  round: false,
  simple: false,
  disabled: false,
  block: false,
  link: false,
  justIcon: false,
  className: "",
  muiClasses: {},
};

export default withStyles(buttonStyle)(RegularButton);
