/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import LinksTopo from "./LinksTopo.jsx";
import Button from "components/CustomButtons/Button.jsx";
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

function Topo({ classes, color, routes, auth, handleDrawerToggle }) {
  const [state, setState] = useState({ auth: null, settings: [] });
  useEffect(() => {
    setState({ auth, settings: routes.settings });
  }, [auth, routes.settings]);

  let brandName = "";
  const setBrandName = (prop) => {
    if (prop.redirect || (prop.items && prop.items.forEach(setBrandName))) return;
    if (prop.path && prop.path !== "/" && new RegExp(`${prop.path}$`).test(window.location.pathname)) {
      brandName = typeof prop.navbarName === "function" ? prop.navbarName(auth) : prop.navbarName;
    }
  };
  routes.menu.forEach(setBrandName);
  state.settings.forEach(setBrandName);

  const appBarClasses = classNames({ [" " + classes[color]]: color });
  return (
    <AppBar className={classes.appBar + appBarClasses + " TopoAppBar"}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button color="transparent" onClick={() => document.location.reload()} className={classes.title}>{brandName}</Button>
        </div>
        <div className="hidePrint">
          <Hidden smDown implementation="css">
            <LinksTopo routes={state.settings} auth={auth} />
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}><Menu /></IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Topo.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Topo);