import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import MainLayout from "./layouts/MainLayout.jsx";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";

import "./assets/css/gantt.css";
import "./assets/css/material-dashboard-react.css?v=1.5.0";
import "./assets/css/main.css";
import "./assets/css/print.css";

// import deepOrange from '@material-ui/core/colors/deepOrange';
// import red from '@material-ui/core/colors/red';

moment.locale("pt-BR");
// import Test from "./redux/index";

// const primary = red[900]; // #F44336
// const accent = deepOrange[500]; // #E040FB
// // const accent = deepOrange['A200']; // #E040FB
// const accent2 = deepOrange.A200; // #E040FB (alternative method)
// console.warn = () => {}; 
// console.error = () => {}; 
const history = createBrowserHistory();

ReactDOM.render(
  <MuiPickersUtilsProvider
    utils={MomentUtils}
    locale="pt-BR"
    libInstance={moment}
  >
    <MainLayout history={history} />
  </MuiPickersUtilsProvider>,
  // <Test />,
  document.getElementById("root")  
);
