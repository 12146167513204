// LinksTopo Component
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Icon from "@material-ui/core/Icon";
import Settings from "@material-ui/icons/Settings";
import Button from "../../components/CustomButtons/Button.jsx";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ListItemText } from "@material-ui/core";
import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

const LinksTopo = ({ classes, routes, auth }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const anchorElMenu = useRef(null);

  useEffect(() => {
    const prepareRoutes = () => {
      const filtered = routes
        .sort((a, b) => {
          const aName =
            typeof a.sidebarName === "function"
              ? a.sidebarName(auth)
              : a.sidebarName || "";
          const bName =
            typeof b.sidebarName === "function"
              ? b.sidebarName(auth)
              : b.sidebarName || "";
          return aName.localeCompare(bName);
        })
        .filter((route) => {
          if (route.devel && !document.procEnv?.API_URL.includes("localhost")) return false;
          if (auth?.permission && !route.devel) {
            const formularios = Object.keys(auth.permission);
            if (!formularios.includes(route.path.replace(document.procEnv?.BASE_PATH || "/", ""))) {
              return false;
            }
          }
          return route.menu !== false && (!route.auth || auth);
        });

      setFilteredRoutes(filtered);
    };

    prepareRoutes();
  }, [auth, routes]);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorElMenu.current && anchorElMenu.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  return (
    <div>
      {filteredRoutes.length > 0 && (
        <div className={classes.manager}>
          <Button
            buttonRef={anchorElMenu}
            color="transparent"
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Configurações"
            aria-expanded={openMenu}
            className={classes.buttonLink}
            onClick={handleToggle}
            style={{ fontSize: "1em" }}
          >
            <Settings className={classes.icons} />
            <Hidden mdUp>
              <p className={classes.linkText}>Configurações</p>
            </Hidden>
          </Button>
          <Poppers
            open={openMenu}
            anchorEl={anchorElMenu.current}
            transition
            disablePortal
            className={classNames({
              [classes.popperClose]: !openMenu,
            }) + " " + classes.pooperNav}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList role="menu">
                      {filteredRoutes.map((menuItem, key) => (
                        <NavLink
                          to={menuItem.path}
                          className={classes.item}
                          activeClassName="active"
                          key={key}
                        >
                          <MenuItem
                            className={classes.itemLink}
                            onClick={handleClose}
                          >
                            <ListItemIcon className={classes.itemIcon}>
                              {typeof menuItem.icon === "string" ? (
                                <Icon>{menuItem.icon}</Icon>
                              ) : (
                                <menuItem.icon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={menuItem.sidebarName}
                              className={classes.itemText}
                              disableTypography
                            />
                          </MenuItem>
                        </NavLink>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      )}
    </div>
  );
};

export default withStyles(headerLinksStyle)(LinksTopo);