// @material-ui/icons
import { Dashboard, Person, Build, List } from "@material-ui/icons";

//https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import asyncComponent from "../layouts/AsyncComponent";

const Login = asyncComponent(() => import("views/Login.jsx"));
const RecuperarSenha = asyncComponent(() => import("views/RecuperarSenha.jsx"));
const RedefinirSenha = asyncComponent(() => import("views/RedefinirSenha.jsx"));
const Grupo = asyncComponent(() => import("views/ad/Grupo.jsx"));
const Pessoa = asyncComponent(() => import("views/ad/Pessoa.jsx"));
const DashboardPage = asyncComponent(() =>
  import("views/Dashboard/Dashboard.jsx")
);
const PainelRequerimento = asyncComponent(() =>
  import("views/tp/PainelRequerimento.jsx")
);
const PainelDebito = asyncComponent(() => import("views/tp/PainelDebito.jsx"));
const AcessoCidadao = asyncComponent(() =>
  import("views/acessoCidadao/AcessoCidadao.jsx")
);
const Risco = asyncComponent(() => import("views/tp/Risco.jsx"));
const VerificarAutenticidadeCertidao = asyncComponent(() =>
  import("views/tp/CertidoesParcelamentos/VerificarAutenticidadeCertidao.jsx")
);
const CertidoesParcelamentos = asyncComponent(() =>
  import("views/tp/CertidoesParcelamentos.jsx")
);
const BaixaManualPagamento = asyncComponent(() =>
  import("views/tp/BaixaManualPagamento.jsx")
);
const Obm = asyncComponent(() => import("views/tp/Obm.jsx"));
const Vencimentos = asyncComponent(() => import("views/tp/Vencimentos.jsx"));
const Municipio = asyncComponent(() => import("views/ad/Municipio.jsx"));
const Unidade = asyncComponent(() => import("views/tp/Unidade.jsx"));
const Regiao = asyncComponent(() => import("views/ad/Regiao.jsx"));
const ValorTaxa = asyncComponent(() => import("views/ad/ValorTaxa.jsx"));
const Configuracao = asyncComponent(() => import("views/tp/Configuracao.jsx"));
const TipoEdificacao = asyncComponent(() =>
  import("views/tp/TipoEdificacao.jsx")
);
const IsencaoTaxa = asyncComponent(() => import("views/tp/IsencaoTaxa.jsx"));
const Imovel = asyncComponent(() => import("views/tp/Imovel.jsx"));
const Proprietario = asyncComponent(() => import("views/tp/Proprietario.jsx"));
const ImportacaoImovels = asyncComponent(() =>
  import("views/tp/ImportacaoImovels.jsx")
);
// eslint-disable-next-line no-unused-vars
const ImportacaodebitosAnteriores = asyncComponent(() =>
  import("views/tp/ImportacaodebitosAnteriores.jsx")
);
const TipoRequerente = asyncComponent(() =>
  import("views/tp/TipoRequerente.jsx")
);
const TipoRequerimento = asyncComponent(() =>
  import("views/tp/TipoRequerimento.jsx")
);
const TipoIsencao = asyncComponent(() => import("views/tp/TipoIsencao.jsx"));
const TipoDocumento = asyncComponent(() =>
  import("views/tp/TipoDocumento.jsx")
);
const NovoRequerimento = asyncComponent(() =>
  import("views/tp/NovoRequerimento.jsx")
);
const Requerente = asyncComponent(() => import("views/tp/Requerente.jsx"));
const Publico = asyncComponent(() => import("views/tp/Publico.jsx"));
const Operacao = asyncComponent(() => import("views/log/Operacao.jsx"));
const Samples = asyncComponent(() => import("views/samples/Samples.jsx"));

const basePath = document.procEnv.BASE_PATH + "/";

const mainRoutes = {
  menu: [
    {
      path: basePath + "dashboard",
      sidebarName: "Painel de Bordo",
      navbarName: "Painel de Bordo",
      icon: Dashboard,
      component: DashboardPage,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "acessoCidadao",
      sidebarName: "Acesso Cidadão",
      navbarName: "Acesso Cidadão",
      icon: Person,
      component: AcessoCidadao,
      permission: "PUB",
      noAuthPermission: true,
    },
    {
      path: basePath + "painelRequerimento",
      sidebarName: "Painel Requerimento",
      navbarName: "Painel Requerimento",
      icon: Dashboard,
      component: PainelRequerimento,
      auth: true,
    },
    {
      path: basePath + "painelDebito",
      sidebarName: "Painel Débito",
      navbarName: "Painel Débito",
      icon: Dashboard,
      component: PainelDebito,
      auth: true,
      permission: "USU",
    },
    {
      group: "Serviços",
      auth: true,
      items: [
        {
          path: basePath + "requerimento",
          sidebarName: "Requerimento",
          navbarName: "Requerimento",
          icon: Dashboard,
          component: NovoRequerimento,
          auth: true,
          noAuthPermission: true,
        },
        {
          path: basePath + "isencao",
          sidebarName: "Isenção de Taxa",
          navbarName: "Isenção de Taxa",
          icon: Dashboard,
          component: IsencaoTaxa,
          auth: true,
          permission: "USU",
        },
        {
          path: basePath + "baixaManualPagamento",
          sidebarName: "Baixa Manual Pagamento",
          navbarName: "Baixa Manual de Pagamento",
          icon: Dashboard,
          component: BaixaManualPagamento,
          auth: true,
          permission: "USU",
        },
        {
          path: basePath + "certidoesParcelamentos",
          sidebarName: "Certidões, 2ª Via e Parcelamentos",
          navbarName: "Certidões, 2ª Via e Parcelamentos",
          icon: Person,
          component: CertidoesParcelamentos,
          auth: true,
          noAuthPermission: true,
        },
        // {
        //   path: basePath + "importacaoImovels",
        //   sidebarName: "Importação de imóveis",
        //   navbarName: "Importação de imóveis",
        //   icon: Dashboard,
        //   component: ImportacaoImovels,
        //   auth: true,
        //   noAuthPermission: true,
        // },
        // {
        //   path: basePath + "importacaodebitosAnteriores",
        //   sidebarName: "Importação Debitos anteriores",
        //   navbarName: "Importação Debitos anteriores",
        //   icon: Dashboard,
        //   component: ImportacaodebitosAnteriores,
        //   auth: true,
        //   noAuthPermission: true,
        // },
      ],
    },
    {
      group: "Cadastros",
      auth: true,
      permission: "USU",
      items: [
        {
          path: basePath + "imovel",
          sidebarName: "Imóvel",
          navbarName: "Imóvel",
          icon: Dashboard,
          component: Imovel,
          auth: true,
          permission: "USU",
        },
        {
          path: basePath + "proprietario",
          sidebarName: "Proprietário",
          navbarName: "Proprietário",
          icon: Dashboard,
          component: Proprietario,
          auth: true,
          permission: "USU",
        },
        {
          path: basePath + "requerente",
          sidebarName: "Requerente",
          navbarName: "Requerente",
          icon: Person,
          component: Requerente,
          auth: true,
          permission: "USU",
        },
        {
          path: basePath + "publico",
          sidebarName: "Público Externo",
          navbarName: "Público Externo",
          icon: Person,
          component: Publico,
          auth: true,
          permission: "USU",
        },
      ],
    },
    {
      group: "Autenticação",
      items: [
        {
          path: basePath + "login",
          sidebarName: (auth) => (!auth ? "Acesso Restrito" : "Logout"),
          navbarName: (auth) => (!auth ? "Acesso Restrito" : "Logout"),
          icon: Person,
          component: Login,
        },
      ],
    },
  ],
  settings: [
    {
      path: basePath + "obm",
      sidebarName: "OBM",
      navbarName: "OBM",
      icon: List,
      component: Obm,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "vencimentos",
      sidebarName: "Vencimentos",
      navbarName: "Vencimentos",
      icon: List,
      component: Vencimentos,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "municipio",
      sidebarName: "Município",
      navbarName: "Município",
      icon: List,
      component: Municipio,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "unidade",
      sidebarName: "Unidade",
      navbarName: "Unidade",
      icon: List,
      component: Unidade,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "regiao",
      sidebarName: "Região",
      navbarName: "Região",
      icon: List,
      component: Regiao,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "valor_taxa",
      sidebarName: "Valor Taxa",
      navbarName: "Valor Taxa",
      icon: List,
      component: ValorTaxa,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "tipo_requerimento",
      sidebarName: "Tipo de Requerimento",
      navbarName: "Tipo de Requerimento",
      icon: List,
      component: TipoRequerimento,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "tipo_requerente",
      sidebarName: "Tipo de Requerente",
      navbarName: "Tipo de Requerente",
      icon: List,
      component: TipoRequerente,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "tipo_documento",
      sidebarName: "Tipo de Documento",
      navbarName: "Tipo de Documento",
      icon: List,
      component: TipoDocumento,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "tipo_isencao",
      sidebarName: "Tipo de Isenção",
      navbarName: "Tipo de Isenção",
      icon: List,
      component: TipoIsencao,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "tipo_edificacao",
      sidebarName: "Tipo de Edificação",
      navbarName: "Tipo de Edificação",
      icon: List,
      component: TipoEdificacao,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "risco",
      sidebarName: "Risco",
      navbarName: "Risco",
      icon: List,
      component: Risco,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "usuario",
      sidebarName: "Usuário",
      navbarName: "Usuário",
      icon: List,
      component: Pessoa,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "grupo",
      sidebarName: "Grupo de Acesso",
      navbarName: "Grupo de Acesso",
      icon: List,
      component: Grupo,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "configuracao",
      sidebarName: "Configuração",
      navbarName: "Configuração",
      icon: List,
      component: Configuracao,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "operacao",
      sidebarName: "Operação",
      navbarName: "Operação",
      icon: List,
      component: Operacao,
      auth: true,
      permission: "USU",
    },
    {
      path: basePath + "exemplos",
      sidebarName: "Exemplos",
      navbarName: "Exemplos",
      icon: Build,
      component: Samples,
      auth: true,
      devel: true,
    },
    {
      path: basePath + "verificar_certidao",
      navbarName: "Verificar Certidão",
      auth: true,
      component: VerificarAutenticidadeCertidao,
      noAuthPermission: true,
    },
    {
      path: basePath + "recuperarsenha",
      navbarName: "Recuperar Senha",
      menu: false,
      component: RecuperarSenha,
    },
    {
      path: basePath + "redefinirsenha",
      navbarName: "Redefinir Senha",
      menu: false,
      component: RedefinirSenha,
    },
  ],
};

export default mainRoutes;
