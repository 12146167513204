/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import sidebarStyle from "../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import LinksTopo from "../Topo/LinksTopo.jsx";

const basePath = document.procEnv.BASE_PATH + "/";

function activeRoute(routeName) {
  let reg = new RegExp(routeName + "$");
  return reg.test(window.location.pathname);
}

const filterMenu = auth => menuItem => {
  if (
    !menuItem.group &&
    menuItem.auth &&
    !menuItem.noAuthPermission &&
    auth &&
    auth.permission
  ) {
    let formularios = Object.keys(auth.permission);
    if (formularios.indexOf(menuItem.path.replace(basePath, "")) < 0) {
      return null;
    }
  }

  if (
    menuItem.redirect ||
    menuItem.menu === false ||
    (menuItem.auth && !(auth !== "" && auth !== null))
  )
    return null;

  return true;
};

const parseMenu = (classes, color, auth) => (menuItem, key) => {
  const active = activeRoute(menuItem.path);

  const listItemClasses = classNames({
    [" " + classes.itemLink]: true,
    [" " + classes[color]]: active,
    [" " + classes.root]: active
  });
  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: active
  });

  if (menuItem.group) {
    let items = menuItem.items.filter(filterMenu(auth));
    if (items.length > 0) {
      return (
        <div key={key}>
          <b style={{ padding: "10px" }}>{menuItem.group}</b>
          {items.map(parseMenu(classes, color, auth))}
        </div>
      );
    }
    return null;
  }
  return (
    <NavLink
      to={menuItem.path}
      className={classes.item}
      activeClassName="active"
      key={key}
    >
      <ListItem button className={listItemClasses}>
        <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
          {typeof menuItem.icon === "string" ? (
            <Icon>{menuItem.icon}</Icon>
          ) : (
            <menuItem.icon />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            typeof menuItem.sidebarName == "function"
              ? menuItem.sidebarName(auth)
              : menuItem.sidebarName
          }
          className={classes.itemText + whiteFontClasses}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  );
};

const Menu = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  const { auth, classes, color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.menu
        .filter(filterMenu(auth))
        .map(parseMenu(classes, color, auth))}
    </List>
  );
  var brand = (
    <div
      className={classes.logo}
      style={{ backgroundColor: "#F9F9F9", color: "black" }}
    >
      <NavLink to={basePath}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </NavLink>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div
            style={{ backgroundColor: "#F9F9F9", color: "black" }}
            className={classes.sidebarWrapper}
          >
            {links}
            <LinksTopo routes={props.routes.settings} auth={auth} />
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div
            style={{ backgroundColor: "#F9F9F9", color: "black" }}
            className={classes.sidebarWrapper}
          >
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Menu);
