import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

// Corrige o componente Transition para aceitar refs
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class AlertDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition} // Usa o componente corrigido
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{
          zIndex: 1301 // Maior que um popover, para 'excluir anexo' aparecer sobre a janela de 'anexos'
        }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {this.props.title || "Título Padrão"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {this.props.message || "Mensagem padrão."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (this.props.disagree) this.props.disagree();
            }}
            color="primary"
          >
            CANCELAR
          </Button>
          <Button
            onClick={() => {
              if (this.props.agree) this.props.agree();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
  agree: PropTypes.func,
  disagree: PropTypes.func
};

export default AlertDialog;
